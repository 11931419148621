import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import PropTypes from 'prop-types';
import { Box, styled, useTheme } from '@mui/material';
import { line, curveNatural } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import { StyledTooltip } from '../../../utils/styles';
import {
  formatYAxis,
  getScaledValue,
  getSmallWidgetY,
  remToPixels,
} from '../Helper/helper';
import useWindowSize from '../../../hooks/useWindowSize';
import {
  WIDGET_NAME,
  CIRCLE_RADIUS_VALUE,
  GENERIC_CONSTANTS,
} from '../../../constants';

const StyledBox = styled(Box)(() => ({
  height: 'inherit',
  width: 'inherit',
}));

export function Tooltip({
  tooltipRequired,
  bars,
  getLineTooltipData,
  isCurrent,
  xScale,
  newYScale,
  keyValue,
  color,
}) {
  const isShowPoint = (paramData, paramKey) => {
    const { data } = paramData.data;
    return data.showPoint && data[paramKey] !== undefined;
  };
  const showCircleCondition = (data, k) =>
    isCurrent ? isShowPoint(data, k) : true;
  return (
    tooltipRequired &&
    bars.map((bar) => {
      const shouldShowCircle = showCircleCondition(bar, keyValue);
      const tooltipTitle = getLineTooltipData(bar.data, keyValue, isCurrent);
      const circleCx = xScale(bar.data.indexValue) + bar.width / 2;
      const circleCy = newYScale(bar.data.data[keyValue]);
      const circleFill = bar.data.data[`${keyValue}_dotColorCode`] ?? color;
      const circleStroke = circleFill;

      const radiusInPixels = remToPixels(CIRCLE_RADIUS_VALUE);
      return (
        shouldShowCircle && (
          <StyledTooltip
            key={bar.keyValue}
            role="tooltip"
            placement="top"
            title={tooltipTitle}
            enterTouchDelay={0}
          >
            {isShowPoint(bar, keyValue) ? (
              <circle
                key={bar.keyValue}
                cx={circleCx}
                cy={circleCy}
                r={radiusInPixels}
                fill={circleFill}
                stroke={circleStroke}
              />
            ) : (
              <circle />
            )}
          </StyledTooltip>
        )
      );
    })
  );
}

Tooltip.propTypes = {
  bars: PropTypes.instanceOf(Array),
  xScale: PropTypes.func,
  newYScale: PropTypes.func,
  keyValue: PropTypes.string,
  color: PropTypes.string,
  getLineTooltipData: PropTypes.func,
  tooltipRequired: PropTypes.bool,
  isCurrent: PropTypes.bool,
};

export default function Pareto({
  data,
  yMaxValue = null,
  yMinValue = null,
  barColors = [],
  xTickValues,
  yTickValues,
  xLabelRotation = 0,
  getLineTooltipData,
  tooltipRequired,
  leftYaxisLegend,
  barKeys,
  lineKeys,
  indexBy,
  widgetType,
  chartTheme,
  enableLabel,
  layers,
  tooltip,
  rightYaxisLegend,
  isExpanded,
  unitOfMeasurement = 'units',
  ...other
}) {
  const { width: screenWidth } = useWindowSize();

  if (!Array.isArray(data) || !data.length) return null;
  const getScaledValueWrapper = (value) => getScaledValue(screenWidth, value);

  const barAriaLabel = (d) => {
    let ariaLabel = `${d.data[indexBy]}:`;
    barKeys?.forEach((barKey) => {
      ariaLabel += `${barKey}: ${d.data[barKey]}, `;
    });
    lineKeys?.forEach((lineKey) => {
      ariaLabel += `${lineKey}: ${d.data[lineKey]}, `;
    });
    return ariaLabel;
  };
  const xtickValue =
    xTickValues && Array.isArray(xTickValues) && xTickValues.length === 0
      ? null
      : xTickValues;
  return (
    <StyledBox data-testid="smf-pareto-chart">
      <ResponsiveBar
        data={data}
        keys={barKeys}
        indexBy={indexBy}
        margin={{
          top: getScaledValueWrapper(10),
          right: getScaledValueWrapper(40),
          bottom: getScaledValueWrapper(70),
          left: getScaledValueWrapper(37),
        }}
        maxValue={yMaxValue}
        minValue={yMinValue}
        theme={chartTheme}
        colors={barColors.length === 0 ? ['#0097A9'] : barColors}
        colorBy="id"
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        axisBottom={{
          orient: 'bottom',
          tickSize: 0,
          tickPadding: getScaledValueWrapper(8),
          tickRotation: xLabelRotation,
          tickValues: xtickValue,
          legend: '',
          legendOffset: getScaledValueWrapper(40),
          legendPosition: 'middle',
          style: {
            fontFamily: 'Open Sans',
          },
        }}
        axisLeft={{
          tickValues: yTickValues,
          tickSize: 0,
          tickPadding: getScaledValueWrapper(1.5),
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: getScaledValueWrapper(-30),
          legend: leftYaxisLegend,
          format: (v) => formatYAxis(v, unitOfMeasurement),
          style: {
            fontFamily: 'Open Sans',
          },
        }}
        gridYValues={yTickValues}
        enableLabel={enableLabel}
        role="application"
        ariaLabel={`Pareto Chart for ${widgetType}`}
        barAriaLabel={barAriaLabel}
        tooltip={tooltip}
        layers={layers}
        {...other}
      />
    </StyledBox>
  );
}

Pareto.propTypes = {
  data: PropTypes.instanceOf(Array),
  yMaxValue: PropTypes.number,
  xTickValues: PropTypes.instanceOf(Array),
  yTickValues: PropTypes.instanceOf(Array),
  xLabelRotation: PropTypes.number,
  getLineTooltipData: PropTypes.func,
  tooltipRequired: PropTypes.bool,
  yMinValue: PropTypes.number,
  barKeys: PropTypes.instanceOf(Array),
  lineKeys: PropTypes.instanceOf(Array),
  indexBy: PropTypes.string,
  widgetType: PropTypes.string,
  chartTheme: PropTypes.shape({}),
  enableLabel: PropTypes.bool,
  layers: PropTypes.instanceOf(Array),
  tooltip: PropTypes.func,
  barColors: PropTypes.instanceOf(Array),
  unitOfMeasurement: PropTypes.string,
  leftYaxisLegend: PropTypes.string,
  rightYaxisLegend: PropTypes.string,
  isExpanded: PropTypes.bool,
};

Pareto.Line = function Line(props) {
  const {
    bars,
    xScale,
    yScale,
    key,
    color,
    getLineTooltipData,
    isCurrent,
    tooltipRequired = true,
    curve,
    rightYaxisLegend,
    innerWidth,
    isExpanded,
    widgetType,
  } = props;
  const theme = useTheme();
  const { width: screenWidth } = useWindowSize();

  const newYScale = scaleLinear().domain([0, 100]).range(yScale.range());

  const createLineGenerator = () => {
    const generator = line()
      .x((bar) => xScale(bar.data.indexValue) + bar.width / 2)
      .y((bar) => newYScale(bar.data.data[key] || 0));

    if (isCurrent) {
      generator.defined((bar) => bar.data.data.showPoint);
    }

    if (curve) {
      generator.curve(curveNatural);
    }

    return generator;
  };
  const lineGenerator = createLineGenerator();

  const barsValue = bars;

  const chartHeight = yScale.range()[0];

  const fixedYValues = isExpanded ? [0, 25, 50, 75, 100] : [0, 50, 100];

  let newTranslateY;

  if (isExpanded) {
    if (widgetType === WIDGET_NAME.PPE_INSIGHTS) {
      newTranslateY = chartHeight;
    } else {
      newTranslateY = chartHeight / 1.6;
    }
  } else {
    newTranslateY = getSmallWidgetY(screenWidth, theme, chartHeight);
  }

  return (
    <>
      <path
        d={lineGenerator(barsValue)}
        fill="none"
        stroke={color}
        style={{ pointerEvents: 'none' }}
      />
      <Tooltip
        tooltipRequired={tooltipRequired}
        bars={bars}
        getLineTooltipData={getLineTooltipData}
        isCurrent={isCurrent}
        xScale={xScale}
        newYScale={newYScale}
        keyValue={key}
        color={color}
      />

      {fixedYValues.map((t) => {
        return (
          <text
            key={t}
            transform={`translate(${innerWidth}, ${newYScale(t)})`}
            style={{
              dominantBaseline: 'mathematical',
              fontSize:
                screenWidth >= theme.breakpoints.values.xl
                  ? '0.688rem'
                  : '0.563rem',
              fill: theme.palette.grey.grey500,
              fontFamily: 'Open Sans',
            }}
            aria-label={t}
          >
            {t}
          </text>
        );
      })}

      <text
        transform={`translate(${
          innerWidth + 20
        }, ${newTranslateY}) rotate(-90)`}
        style={{
          fontFamily: 'Open Sans',
          fill: theme.palette.text.primary,
        }}
      >
        <tspan
          style={{
            fontFamily: 'Open Sans',
            fontSize:
              screenWidth >= theme.breakpoints.values.xl
                ? '0.625rem'
                : '0.56rem',
            fill: theme.palette.text.primary,
          }}
          x={getScaledValue(10)}
          dy={screenWidth >= theme.breakpoints.values.lg ? '2em' : '1em'}
          aria-label={GENERIC_CONSTANTS.CUMMULATIVE_PERCENTAGE}
        >
          {rightYaxisLegend}
        </tspan>
      </text>
    </>
  );
};

Pareto.Line.propTypes = {
  bars: PropTypes.instanceOf(Array),
  xScale: PropTypes.func,
  yScale: PropTypes.func,
  key: PropTypes.string,
  color: PropTypes.string,
  getLineTooltipData: PropTypes.func,
  tooltipRequired: PropTypes.bool,
  isCurrent: PropTypes.bool,
  curve: PropTypes.bool,
  rightYaxisLegend: PropTypes.string,
  innerWidth: PropTypes.number,
  isExpanded: PropTypes.bool,
  widgetType: PropTypes.string,
};
