import React from 'react';
import { PropTypes } from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { CircularProgress } from '@mui/material';
import {
  StyledUploadImageBox,
  StyledUploadImageText,
  CustomBackdrop,
  CustomBox,
  StyledReUploadButton,
  StyledUploadIconButton,
} from './styles';
import ThemeWrapper from '../../utils/ThemeWrapper';
import UploadImageButton from '../../assets/svgs/UploadImageButton.svg';
import { UPLOAD_IMAGE_CONSTANTS } from '../../constants';

function UploadImage({
  isLoading,
  isDark,
  getUploadUrl,
  isReuploadButtonVisible,
  skuImageUrl,
  requiredFileTypeObject,
  isDisabled,
  onWrongFileSelected,
}) {
  function onDropAccepted(acceptedFilesData) {
    getUploadUrl(acceptedFilesData[0]);
  }
  function getDropzoneProps(isPropEnabled, getRootProps) {
    return isPropEnabled ? getRootProps({ className: 'dropzone' }) : {};
  }
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted,
    onDropRejected: onWrongFileSelected,
    accept: requiredFileTypeObject,
    disabled: isDisabled,
  });
  if (isReuploadButtonVisible) {
    return (
      <ThemeWrapper isDark={isDark}>
        <StyledReUploadButton
          data-testid="smf-upload-image-reupload-button"
          variant="outlined"
          disabled={skuImageUrl === '' || isLoading}
          {...getDropzoneProps(!isLoading, getRootProps)}
        >
          <input {...getInputProps()} />
          {UPLOAD_IMAGE_CONSTANTS.REUPLOAD_IMAGE}
        </StyledReUploadButton>
      </ThemeWrapper>
    );
  }

  return (
    <ThemeWrapper isDark={isDark}>
      <StyledUploadImageBox
        data-testid="smf-upload-image"
        isLoading={isLoading}
        isDisabled={isDisabled}
        {...getDropzoneProps(!isLoading, getRootProps)}
      >
        {!isLoading && (
          <>
            <input {...getInputProps()} />
            <StyledUploadIconButton isDisabled={isDisabled}>
              <UploadImageButton
                className="upload-button"
                data-testid="smf-upload-image-upload-button"
                width="3.175rem"
                height="3.175rem"
              />
            </StyledUploadIconButton>
            <StyledUploadImageText isDisabled={isDisabled}>
              {UPLOAD_IMAGE_CONSTANTS.UPLOAD_IMAGE}
            </StyledUploadImageText>
          </>
        )}
        {isLoading && (
          <>
            <CustomBackdrop invisible open />
            <CustomBox>
              <CircularProgress
                color="primary"
                size={32}
                data-testid="smf-upload-image-circular-progress"
              />
            </CustomBox>
          </>
        )}
      </StyledUploadImageBox>
    </ThemeWrapper>
  );
}

export default UploadImage;

UploadImage.propTypes = {
  isLoading: PropTypes.bool,
  isDark: PropTypes.bool,
  getUploadUrl: PropTypes.func,
  isReuploadButtonVisible: PropTypes.bool,
  skuImageUrl: PropTypes.string,
  requiredFileTypeObject: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  onWrongFileSelected: PropTypes.func,
};
