const getTimeFromTimezone = (timeZone) => {
  if (!timeZone) return '';

  const datetime = new Date().toLocaleString('en-US', { timeZone });
  const date = new Date(datetime);
  let hours = date.getHours().toString();
  let minutes = date.getMinutes().toString();
  hours = hours.length === 1 ? `0${hours}` : hours;
  minutes = minutes.length === 1 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
};

const isEqual = (valueA, valueB) => {
  return valueA === valueB;
};
const checkAndOperator = (a, b) => {
  return a && b;
};

export { getTimeFromTimezone, isEqual, checkAndOperator };
