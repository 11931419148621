import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import PropTypes from 'prop-types';
import { tooltipClasses, Box, useTheme } from '@mui/material';
import { line, curveNatural } from 'd3-shape';
import {
  GENERIC_CONSTANTS,
  WIDGET_NAME,
  CIRCLE_RADIUS_VALUE,
} from '../../../constants';
import { StyledTooltip } from '../../../utils/styles';
import { formatYAxis, getScaledValue, remToPixels } from '../Helper/helper';
import useWindowSize from '../../../hooks/useWindowSize';
import { checkAndOperator, isEqual } from '../../../utils/helpers';

export default function LineBar({
  data,
  yMaxValue = null,
  yMinValue = null,
  barColors = [],
  xTickValues,
  yTickValues,
  xLabelRotation = 0,
  getLineTooltipData,
  tooltipRequired,
  barKeys,
  lineKeys,
  indexBy,
  widgetType,
  chartTheme,
  enableLabel,
  layers,
  tooltip,
  yaxisLegend,
  ylegendPosition = 'middle',
  barColourMapper,
  unitOfMeasurement = 'units',
  formatYaxisLabel,
  ...other
}) {
  /**
   * custom hook to get the screen width
   */
  const { width: screenWidth } = useWindowSize();
  /**
   * if data is empty or not an array, return null
   */
  if (!Array.isArray(data) || !data.length) return null;

  const getScaledValueWrapper = (value, multiplier) => {
    const scaledValue = getScaledValue(screenWidth, value);
    if (scaledValue !== value && multiplier && typeof multiplier === 'number') {
      return value * multiplier;
    }
    return scaledValue;
  };

  return (
    <Box
      sx={(theme) => ({
        width: 'inherit',
        height: 'inherit',
        [theme.breakpoints.up('xl')]: {
          height: '90%',
        },
      })}
      data-testid="smf-bar-chart"
    >
      <ResponsiveBar
        data={data}
        keys={barKeys}
        indexBy={indexBy}
        margin={{
          top: getScaledValueWrapper(10),
          right: getScaledValueWrapper(10),
          bottom: getScaledValueWrapper(60),
          left: getScaledValueWrapper(35),
        }}
        maxValue={yMaxValue}
        minValue={yMinValue}
        theme={chartTheme}
        colors={barColors}
        colorBy="id"
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        axisBottom={{
          orient: 'bottom',
          tickSize: 0,
          tickPadding: getScaledValueWrapper(8),
          tickRotation: xLabelRotation,
          tickValues:
            xTickValues &&
            Array.isArray(xTickValues) &&
            xTickValues.length === 0
              ? null
              : xTickValues,
          legend: '',
          legendOffset: getScaledValueWrapper(40),
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickValues: yTickValues,
          tickSize: 0,
          tickPadding: getScaledValueWrapper(1),
          tickRotation: 0,
          legendPosition: ylegendPosition,
          legendOffset: getScaledValueWrapper(-30),
          legend:
            widgetType === WIDGET_NAME.LINE_BALANCING
              ? formatYaxisLabel
              : yaxisLegend,
          format: (v) => formatYAxis(v, unitOfMeasurement),
        }}
        gridYValues={yTickValues}
        enableLabel={enableLabel}
        role="application"
        ariaLabel={`LineBar Chart for ${widgetType}`}
        barAriaLabel={(d) => {
          let barAriaLabel = `${d.data[indexBy]}:`;
          barKeys?.forEach((barKey) => {
            barAriaLabel += `${barKey}: ${d.data[barKey]}, `;
          });
          lineKeys?.forEach((lineKey) => {
            barAriaLabel += `${lineKey}: ${d.data[lineKey]}, `;
          });
          return barAriaLabel;
        }}
        tooltip={tooltip}
        layers={layers}
        {...other}
      />
    </Box>
  );
}

LineBar.propTypes = {
  data: PropTypes.instanceOf(Array),
  yMaxValue: PropTypes.number,
  xTickValues: PropTypes.instanceOf(Array),
  yTickValues: PropTypes.instanceOf(Array),
  xLabelRotation: PropTypes.number,
  getLineTooltipData: PropTypes.func,
  tooltipRequired: PropTypes.bool,
  yMinValue: PropTypes.number,
  barKeys: PropTypes.instanceOf(Array),
  lineKeys: PropTypes.instanceOf(Array),
  indexBy: PropTypes.string,
  widgetType: PropTypes.string,
  chartTheme: PropTypes.shape({}),
  enableLabel: PropTypes.bool,
  layers: PropTypes.instanceOf(Array),
  tooltip: PropTypes.func,
  barColourMapper: PropTypes.func,
  barColors: PropTypes.instanceOf(Array),
  unitOfMeasurement: PropTypes.string,
  yaxisLegend: PropTypes.string,
  ylegendPosition: PropTypes.string,
  formatYaxisLabel: PropTypes.node,
};

LineBar.Line = function Line(props) {
  const {
    bars,
    xScale,
    yScale,
    key,
    color,
    getLineTooltipData,
    isCurrent,
    tooltipRequired = true,
    curve,
    chartDataLen,
    widgetName,
    cardSize,
    isExpanded,
    throughputLabels,
  } = props;
  const { width: screenWidth } = useWindowSize();
  const theme = useTheme();

  let updatedBars;

  if (
    checkAndOperator(
      isEqual(chartDataLen, 1),
      isEqual(widgetName, WIDGET_NAME.LINE_BALANCING)
    )
  ) {
    const indexToDuplicate = 0;
    let extendedRightVar = isExpanded ? 2.2 : 4;

    if (checkAndOperator(isEqual(cardSize, 'medium'), !isExpanded)) {
      extendedRightVar = 2.7;
    } else if (checkAndOperator(isEqual(cardSize, 'large'), !isExpanded)) {
      extendedRightVar = 2.5;
    }

    if (
      checkAndOperator(screenWidth >= theme.breakpoints.values.xl, !isExpanded)
    ) {
      extendedRightVar = 2.9;
    } else if (
      checkAndOperator(screenWidth >= theme.breakpoints.values.lg, !isExpanded)
    ) {
      extendedRightVar = 3.8;
    }

    const updatedWidthLeft =
      bars[indexToDuplicate].width / 4 -
      (screenWidth >= theme.breakpoints.values.xl ? 370 : 290);
    const updatedWidthRight = bars[indexToDuplicate].width * extendedRightVar;
    const duplicatedItemLeft = {
      ...bars[indexToDuplicate],
      width: updatedWidthLeft,
    };
    const duplicatedItemRight = {
      ...bars[indexToDuplicate],
      width: updatedWidthRight,
    };

    updatedBars = [...bars];
    updatedBars.splice(
      indexToDuplicate + 1,
      0,
      duplicatedItemLeft,
      duplicatedItemRight
    );
  }
  let maxKeys = 0;

  bars.forEach((item) => {
    const dataKeys = Object.keys(item.data.data);
    const filteredKeys = dataKeys.filter(
      (f) =>
        f !== GENERIC_CONSTANTS.TIMESTAMP &&
        f !== GENERIC_CONSTANTS.PREVIOUS_7DAYS_MA &&
        f !== GENERIC_CONSTANTS.SHOWPOINT
    );
    const numKeys = filteredKeys.length;

    if (numKeys > maxKeys) {
      maxKeys = numKeys;
    }
  });
  const createLineGenerator = () => {
    const generator = line()
      .x((bar) =>
        isEqual(widgetName, WIDGET_NAME.CHANGEOVER_TIME)
          ? bar.x + (maxKeys * bar.width) / 2
          : xScale(bar.data.indexValue) + bar.width / 2
      )
      .y((bar) => yScale(bar.data.data[key] || 0));

    if (isCurrent) {
      generator.defined((bar) => bar.data.data.showPoint);
    }

    if (curve) {
      generator.curve(curveNatural);
    }

    return generator;
  };
  const lineGenerator = createLineGenerator();

  const isShowPoint = (paramData, paramKey) => {
    const { data } = paramData.data;
    if (widgetName === WIDGET_NAME.LINE_BALANCING) {
      return data.showPoint;
    }

    return data.showPoint && data[paramKey] !== undefined;
  };
  const showCircleCondition = (data, k) =>
    isCurrent ? isShowPoint(data, k) : true;

  const barsValue = checkAndOperator(
    chartDataLen === 1,
    widgetName === WIDGET_NAME.LINE_BALANCING
  )
    ? updatedBars
    : bars;

  const radiusInPixels = remToPixels(CIRCLE_RADIUS_VALUE);
  return (
    <>
      <path
        d={
          isCurrent
            ? lineGenerator(barsValue)
            : lineGenerator(barsValue.filter((d) => isShowPoint(d, key)))
        }
        fill="none"
        stroke={color}
        style={{ pointerEvents: 'none' }}
        strokeWidth={widgetName === WIDGET_NAME.LINE_BALANCING && 2}
      />
      {tooltipRequired &&
        bars.map((bar) => {
          return (
            showCircleCondition(bar, key) && (
              <StyledTooltip
                key={bar.key}
                role="tooltip"
                placement="top"
                title={getLineTooltipData(
                  bar.data,
                  key,
                  isCurrent,
                  throughputLabels
                )}
                enterTouchDelay={0}
                sx={{
                  [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: theme.palette.grey.grey600,
                    padding: '.25rem',
                  },
                }}
              >
                {isShowPoint(bar, key) ? (
                  <circle
                    key={bar.key}
                    cx={
                      widgetName === WIDGET_NAME.CHANGEOVER_TIME
                        ? bar.x + (maxKeys * bar.width) / 2
                        : xScale(bar.data.indexValue) + bar.width / 2
                    }
                    cy={yScale(bar.data.data[key] || 0)}
                    r={radiusInPixels}
                    fill={bar.data.data[`${key}_dotColorCode`] ?? color}
                    stroke={bar.data.data[`${key}_dotColorCode`] ?? color}
                  />
                ) : (
                  <circle />
                )}
              </StyledTooltip>
            )
          );
        })}
    </>
  );
};

LineBar.Line.propTypes = {
  bars: PropTypes.instanceOf(Array),
  xScale: PropTypes.func,
  yScale: PropTypes.func,
  key: PropTypes.string,
  color: PropTypes.string,
  getLineTooltipData: PropTypes.func,
  tooltipRequired: PropTypes.bool,
  isCurrent: PropTypes.bool,
  chartDataLen: PropTypes.number,
  curve: PropTypes.bool,
  widgetName: PropTypes.string,
  cardSize: PropTypes.string,
  throughputLabels: PropTypes.instanceOf(Array),
  isExpanded: PropTypes.bool,
};
