import React from 'react';
import { Box, Card } from '@mui/material';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DefaultIcon from './svg/default.svg';
import {
  Description,
  HeaderTitle,
  PrimaryButton,
  IconContainer,
  AddOnFeatureIcon,
  CoreFeatureIcon,
} from './style';
import ThemeWrapper from '../../utils/ThemeWrapper';
import SmfTooltip from '../SmfTooltip';

function SmfTile({
  Icon,
  title,
  description,
  buttonText,
  onButtonClickHandler,
  isExternal = false,
  isCore = false,
  isDark = false,
}) {
  return (
    <ThemeWrapper isDark={isDark}>
      <Card
        sx={{
          padding: '1rem',
          border: '1px solid #FFFFFF3B',
          height: '100%',
          position: 'relative',
          ...(!isCore && { display: 'flex', alignItems: 'center' }),
        }}
      >
        {!isCore ? (
          <Box>
            <AddOnFeatureIcon>
              {Icon ? (
                <Icon
                  width="2.664rem"
                  height="2.664rem"
                  data-testid="SmfTile-non-core-default-icon"
                />
              ) : (
                <DefaultIcon data-testid="SmfTile-non-core-default-icon" />
              )}
            </AddOnFeatureIcon>
          </Box>
        ) : (
          <IconContainer>
            <CoreFeatureIcon>
              {Icon ? (
                <Icon
                  width="2.664rem"
                  height="2.664rem"
                  data-testid="SmfTile-core-default-icon"
                />
              ) : (
                <DefaultIcon data-testid="SmfTile-core-default-icon" />
              )}
            </CoreFeatureIcon>
          </IconContainer>
        )}
        <Box sx={{ paddingBottom: '3.8rem' }}>
          <Box>
            <SmfTooltip
              title={title}
              placement="bottom-start"
              disableHoverListener={false}
              data-testid="Smf-title-tooltip"
            >
              <HeaderTitle data-testid="SmfTile-title">{title}</HeaderTitle>
            </SmfTooltip>
            <SmfTooltip
              title={description}
              placement="bottom-start"
              disableHoverListener={false}
              data-testid="Smf-description-tooltip"
            >
              <Description data-testid="SmfTile-description" isCore={isCore}>
                {description}
              </Description>
            </SmfTooltip>
          </Box>
          <PrimaryButton
            data-testid="SmfTile-actionButton"
            onClick={onButtonClickHandler}
          >
            {buttonText || 'Launch'}
            {isExternal && (
              <OpenInNewIcon
                sx={{
                  marginLeft: '0.5rem',
                  width: '1.25rem',
                  height: '1.25rem',
                }}
              />
            )}
          </PrimaryButton>
        </Box>
      </Card>
    </ThemeWrapper>
  );
}

export default SmfTile;
SmfTile.propTypes = {
  Icon: PropTypes.node,
  isCore: PropTypes.bool,
  isDark: PropTypes.bool,
  isExternal: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClickHandler: PropTypes.func,
};
