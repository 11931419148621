import { styled, Button, Typography, Box } from '@mui/material';

export const PrimaryButton = styled(Button)(({ theme }) => ({
  marginRight: '1rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  textTransform: 'none',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '0.25rem',
  minWidth: '5.0625rem',
  height: '2.25rem',
  color: theme.palette.primary.contrast,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  marginTop: '1rem',
  position: 'absolute',
  bottom: '1.375rem',
}));

export const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1.5rem',
  letterSpacing: '0.009rem',
  textAlign: 'left',
  color: theme.palette.primary.main,
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
}));

export const Description = styled(Typography)(({ theme, isCore }) => ({
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  fontWeight: '400',
  lineHeight: '1.251rem',
  letterSpacing: '0.009rem',
  textAlign: 'left',
  color: theme.palette.text.primary,
  ...(!isCore && {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    cursor: 'pointer',
  }),
}));

export const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0.5rem',
  svg: { path: { fill: theme.palette.text.primary } },
}));

export const CoreFeatureIcon = styled(Box)(() => ({
  width: '2.664rem',
  height: '2.664rem',
}));

export const AddOnFeatureIcon = styled(Box)(({ theme }) => ({
  width: '2.664rem',
  height: '2.664rem',
  marginRight: '0.75rem',
  svg: { path: { fill: theme.palette.primary.main } },
}));
