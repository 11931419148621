import {
  Box,
  styled,
  Typography,
  Backdrop,
  Button,
  IconButton,
} from '@mui/material';

export const StyledUploadImageBox = styled(Box, {
  shouldForwardProp: (props) => props !== 'isLoading' && props !== 'isDisabled',
})(({ theme, isLoading, isDisabled }) => ({
  marginTop: '1rem',
  height: '12.5rem',
  borderRadius: '0.5rem',
  width: '49.375rem',
  borderStyle: 'dashed',
  borderWidth: '0.13rem',
  borderColor: isDisabled
    ? theme.palette.action.disabled
    : theme.palette.primary.main,
  gap: '1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    cursor: !isLoading && !isDisabled ? 'pointer' : 'inherit',
  },
}));

export const StyledUploadImageText = styled(Typography, {
  shouldForwardProp: (props) => props !== 'isDisabled',
})(({ theme, isDisabled }) => ({
  padding: 0,
  display: 'flex',
  color: isDisabled
    ? theme.palette.action.disabled
    : theme.palette.text.primary,
  fontSize: '1rem',
}));

export const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
}));

export const CustomBox = styled(Box)(({ theme }) => ({
  color: theme.palette.background.default,
  textAlign: 'center',
}));
export const StyledReUploadButton = styled(Button)({
  height: '1.875rem',
  width: '5.563rem',
  textTransform: 'none',
  padding: 0,
});
export const StyledUploadIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isDisabled',
})(({ theme, isDisabled }) => ({
  cursor: isDisabled && 'inherit',
  ':hover': {
    background: isDisabled && 'none',
  },
  '.upload-button': {
    path: {
      fill: isDisabled
        ? theme.palette.text.disabled
        : theme.palette.primary.main,
    },
  },
}));
