const ALERT_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
};

const WIDGET_ICON = {
  EXPAND: 'expand',
  COLLAPSE: 'collapse',
};

const TEST = {};

const SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
};

const VARIANT = {
  OUTLINED: 'outlined',
};

const OPERATING_CONDITIONS_COLUMN = {
  ASSET: 'Asset',
  PARAMETER_TYPE: 'Parameter Type',
  PARAMETER: 'Parameter',
  UOM: 'UoM',
  RECIPE: 'Recipe',
  SET_POINT_LL: 'LL',
  SET_POINT_TARGET: 'Target',
  SET_POINT_UL: 'UL',
  SET_POINT_VALUE: 'SV',
  PRESENT_VALUE: 'PV',
  RISK_CATEGORY: 'Risk Category',
};

const SINGLE_GAUGE_MIN_VAL = '0';

const WIDGET_NAME = {
  EQUIPMENT_FAULTS: 'EQUIPMENT_FAULTS',
  LINE_BALANCING: 'LINE_BALANCING',
  CHANGEOVER_TIME: 'CHANGEOVER_TIME',
  PPE_INSIGHTS: 'PPE_INSIGHTS',
};

const CHART_TYPE = {
  SINGLE_GAUGE: 'singleGauge',
};

const BI_CONSTANTS = {
  EXPLORE_DATA_BUTTON_TYPOGRAPHY: 'Explore data in ',
};

const TEST_IDS = {
  EXPLORE_DATA_BUTTON: 'explore-data-button',
  PLANT_TIME: 'Plant Time',
  PLANT_TIME_SKELETON: 'plant-time-skeleton',
  YP_RCA_CHART: 'YP-RCA-CHART',
  SHOW_LEGEND: 'legend',
};

const MESSAGE_STRINGS = {
  PLANT_TIME: 'Plant Time',
  SORT_BUTTON: 'Sort Button',
};

const SORT_ORDER = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

const DCL_CHART_FIXED_KEYS = {
  AVERAGE: 'Average',
  DYNAMIC_UCL: 'Dynamic UCL',
  DYNAMIC_LCL: 'Dynamic LCL',
  ACTUAL_FPY: 'Actual FPY',
  PREDICTED_FPY: 'Predicted FPY',
  CONTRIBUTION_PERCENTAGE: 'Contribution percentage',
};

const DCL_CHART_FIXED_KEYS_LIST = Object.entries(DCL_CHART_FIXED_KEYS).map(
  (val) => val[1]
);
const GENERIC_CONSTANTS = {
  TIMESTAMP: 'timestamp',
  PREVIOUS_7DAYS_MA: 'previousSevenDaysMA',
  SHOWPOINT: 'showPoint',
  TABLE_HEADER: 'table-header',
  CUMMULATIVE_PERCENTAGE: 'Cummulative Percentage',
};
const UPLOAD_IMAGE_CONSTANTS = {
  UPLOAD_IMAGE: 'Upload SKU Image',
  REUPLOAD_IMAGE: 'Re-Upload',
};

const USE_CASE = {
  WORK_ORDER: 'Work Order',
  CONSTRAINT_STATUS: 'Constraint Status',
};

const CIRCLE_RADIUS_VALUE = 0.3;
export {
  ALERT_TYPES,
  TEST,
  SIZE,
  VARIANT,
  OPERATING_CONDITIONS_COLUMN,
  SINGLE_GAUGE_MIN_VAL,
  WIDGET_NAME,
  BI_CONSTANTS,
  TEST_IDS,
  CHART_TYPE,
  MESSAGE_STRINGS,
  SORT_ORDER,
  DCL_CHART_FIXED_KEYS,
  DCL_CHART_FIXED_KEYS_LIST,
  GENERIC_CONSTANTS,
  UPLOAD_IMAGE_CONSTANTS,
  USE_CASE,
  CIRCLE_RADIUS_VALUE,
  WIDGET_ICON,
};
